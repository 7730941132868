import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { listUsers, removeUser, updateUser } from '../api';
import 'dayjs';

import Header from '../components/Header';
import Footer from '../components/Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 110px 190px 130px 110px 90px 90px 60px;
  align-items: center;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

interface UserInfo {
  username: string;
  email: string;
  phone: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  wallet?: string;
  joinedAt: Date;
  recommender?: string;
}

import Icon_KR from 'img/korea.png';
import Icon_Vietnam from 'img/vietnam.png';
import dayjs from 'dayjs';

const CountryInfo = [
  { number: '+82', icon: Icon_KR },
  { number: '+84', icon: Icon_Vietnam },
];

export default withRouter(function AdminManageUsers(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);

  const [dropdownOn, setDropdownOn] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<number>(0);

  const [editUser, setEditUser] = useState<UserInfo | null>(null);
  const [allUsers, setAllUsers] = useState<UserInfo[]>([]);
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [search, setSearch] = useState('');

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [wallet, setWallet] = useState<string | undefined>('');
  const [recommender, setRecommender] = useState<string | undefined>('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listUsers();
      if (result == null) {
        return;
      }
      setAllUsers(result);
      setUsers(result);
    })();
  }, []);

  useEffect(() => {
    setUsers(
      allUsers.filter(
        (info) =>
          info.email.toLowerCase().includes(search.toLowerCase()) ||
          info.username.toLowerCase().includes(search.toLowerCase()) ||
          info.phone.replace('-', '').replace('+', '').includes(search.replace('-', '').replace('+', '')),
      ),
    );
  }, [search]);

  // account.recommendees = Array.from({ length: 312 }, (v, i) => `id${i}`);

  return (
    <div className="App">
      <Modal
        ariaHideApp={false}
        isOpen={editUser != null}
        onAfterOpen={() => {
          if (editUser == null) return;
          setId(editUser.username);
          setPw('');
          for (let i = 0; i < CountryInfo.length; i++) {
            const country = CountryInfo[i];
            if (editUser.phone.startsWith(country.number)) {
              setCurrentCountry(i);
              setPhone(editUser.phone.substring(country.number.length));
            }
          }
          setEmail(editUser.email);
          setWallet(editUser.wallet);
          setRecommender(editUser.recommender);
        }}
        className="admin-edit-user-modal"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div className="admin-edit-user-modal-h1">회원 정보 수정</div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">아이디</div>
          <div className="admin-edit-user-modal-h2">비밀번호</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <input className="admin-edit-user-modal-input " type="text" value={id} disabled={true}></input>
          <input
            className="admin-edit-user-modal-input "
            type="password"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            placeholder="비밀번호 변경을 원하는 경우 입력하세요."
          ></input>
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">전화번호</div>
          <div className="admin-edit-user-modal-h2">이메일</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '80px', height: '32px', position: 'relative' }}>
              <button className="admin-dropdown" onClick={() => setDropdownOn((val) => !val)}>
                <img className="admin-dropdown-img" src={CountryInfo[currentCountry].icon} />
                <div className="admin-dropdown-label">{CountryInfo[currentCountry].number}</div>
                <div className="admin-dropdown-arrow" />
              </button>
              {dropdownOn && (
                <div className="admin-dropdown-control">
                  {CountryInfo.map((e, i) => {
                    return (
                      <button
                        type="button"
                        className="admin-dropdown-menu"
                        onClick={() => {
                          setDropdownOn(false);
                          setCurrentCountry(i);
                        }}
                      >
                        <img className="admin-dropdown-img" src={e.icon} />
                        <div style={{ width: '4px' }} />
                        <div className="admin-dropdown-label">{e.number}</div>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            <span style={{ margin: 'auto' }} />
            <div style={{ width: '294px' }}>
              <input className="admin-edit-user-modal-input " type="text" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
            </div>
          </div>
          <input className="admin-edit-user-modal-input " type="text" value={email} disabled={true}></input>
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">회원 전용 이더리움 지갑 주소</div>
          <div className="admin-edit-user-modal-h2">추천인</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <input
            className="admin-edit-user-modal-input "
            type="text"
            value={wallet ? wallet : ''}
            onChange={(e) => setWallet(e.target.value)}
          ></input>
          <input className="admin-edit-user-modal-input " type="text" value={recommender ? recommender : ''} disabled={true}></input>
        </div>
        <div style={{ height: '34px' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '96px', height: '44px' }}>
            <button
              className="admin-btn-cancel"
              onClick={() => {
                if (editUser == null) return;
                (async () => {
                  const result = await removeUser(editUser.username);
                  if (result && result) {
                    window.location.reload();
                  }
                })();
              }}
            >
              회원 삭제
            </button>
          </div>
          <div style={{ width: '120px', height: '44px' }}>
            <button
              className="admin-btn-confirm"
              onClick={() => {
                if (editUser == null) return;
                if (pw == '' && editUser.phone == CountryInfo[currentCountry].number + phone && editUser.wallet == wallet) {
                  setEditUser(null);
                  return;
                }

                (async () => {
                  const result = await updateUser(editUser.username, {
                    password: pw,
                    phone: CountryInfo[currentCountry].number + phone,
                    wallet: wallet,
                  });
                  if (result && result) {
                    window.location.reload();
                  }
                })();
              }}
            >
              수정 완료
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="admin-box-title">회원 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <ColumnTitle>유저 아이디</ColumnTitle>
            <ColumnTitle>이메일</ColumnTitle>
            <ColumnTitle>전화번호</ColumnTitle>
            <ColumnTitle>소유 포인트</ColumnTitle>
            <ColumnTitle>가입일</ColumnTitle>
            <ColumnTitle>추천인</ColumnTitle>
            <ColumnTitle></ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {users.slice(page * 10, Math.min((page + 1) * 10, users.length)).map((user) => (
            <RowData info={user} onClick={() => setEditUser(user)} />
          ))}
          {users.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(users.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(users.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(users.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(users.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(users.length / maxUserCount) - 1}
                    >
                      {Math.ceil(users.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(users.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(users.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: UserInfo; onClick: () => void }) => {
  return (
    <>
      <ReactTooltip
        id={`points_${props.info.username}`}
        getContent={() => {
          return (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(4, 1fr)',
                width: '190px',
                height: '76px',
              }}
            >
              <div className="admin-tooltip-h1">데모 포인트:</div>
              <div className="admin-tooltip-h2">{props.info.pointA}P</div>
              <div className="admin-tooltip-h1">토너먼트 포인트:</div>
              <div className="admin-tooltip-h2">{props.info.pointB}P</div>
              <div className="admin-tooltip-h1">라이브 포인트:</div>
              <div className="admin-tooltip-h2">{props.info.pointC}P</div>
              <div className="admin-tooltip-h1">이더리움 포인트:</div>
              <div className="admin-tooltip-h2">{props.info.pointD}P</div>
            </div>
          );
        }}
        place="bottom"
        offset={{ right: 95 }}
        clickable={false}
        type={'dark'}
        arrowColor={'transparent'}
      />
      <Row>
        <Columns>
          <div className="admin-box-row-h1">{props.info.username}</div>
          <div className="admin-box-row-h2">{props.info.email}</div>
          <div className="admin-box-row-h3">{props.info.phone}</div>
          <button className="admin-box-row-h4" data-for={`points_${props.info.username}`} data-tip="">
            포인트 보기
          </button>
          <div className="admin-box-row-h5">{dayjs(props.info.joinedAt).format('YYYY.MM.DD')}</div>
          <div className="admin-box-row-h5">{props.info.recommender}</div>
          <button className="admin-box-row-b1" onClick={props.onClick}>
            수정
          </button>
        </Columns>
      </Row>
    </>
  );
};
