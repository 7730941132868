import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import 'dayjs';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';
import dayjs from 'dayjs';
import { InquiryInfo, listBusinessInquiries, removeBusinessInquiry } from '../api';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 80px 240px 80px 140px 100px 140px;
  align-items: center;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

export default withRouter(function AdminManageEnquiries(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);
  const [allEnquiries, setAllEnquiries] = useState<InquiryInfo[]>([]);
  const [enquiries, setEnquiries] = useState<InquiryInfo[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listBusinessInquiries();
      if (result == null) {
        return;
      }
      console.log(result);
      setAllEnquiries(result);
      setEnquiries(result);
    })();
  }, []);

  useEffect(() => {
    setEnquiries(
      allEnquiries.filter(
        (info) => info.author.toLowerCase().includes(search.toLowerCase()) || info.title.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search]);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="admin-box-title">고객센터 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <div style={{ width: '10px' }} />
            <div style={{ width: '114px' }}>
              <button
                className="admin-box-b1"
                onClick={() => {
                  props.history.push('/write-notice');
                }}
              >
                새 공지 작성
              </button>
            </div>
          </div>
          <div style={{ height: '10px' }} />
          <div style={{ display: 'flex' }}>
            <Link to="/manage-notices" className="admin-box-tab">
              공지사항
            </Link>
            <div style={{ width: '20px' }} />
            <Link to="/manage-enquiries" className="admin-box-tab">
              1대1 문의하기
            </Link>
            <div style={{ width: '20px' }} />
            <Link to="/manage-faqs" className="admin-box-tab">
              자주하는 질문
            </Link>
            <div style={{ width: '20px' }} />
            <button className="admin-box-tab" disabled={true}>
              비즈니스 1대1
            </button>
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <ColumnTitle>카테고리</ColumnTitle>
            <ColumnTitle>제목</ColumnTitle>
            <ColumnTitle>작성자</ColumnTitle>
            <ColumnTitle>날짜</ColumnTitle>
            <ColumnTitle>답변 여부</ColumnTitle>
            <ColumnTitle>관리</ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {enquiries.slice(page * 10, Math.min((page + 1) * 10, enquiries.length)).map((info) => (
            <RowData
              info={info}
              onRemove={() => {
                (async () => {
                  const result = await removeBusinessInquiry(info.id);
                  if (result && result) window.location.reload();
                })();
              }}
              onAnswer={() => {
                props.history.push(`/answer-business-enquiry?id=${info.id}`);
              }}
            />
          ))}
          {enquiries.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(enquiries.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(enquiries.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(enquiries.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(enquiries.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(enquiries.length / maxUserCount) - 1}
                    >
                      {Math.ceil(enquiries.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(enquiries.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(enquiries.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: InquiryInfo; onAnswer: () => void; onRemove: () => void }) => {
  return (
    <Row>
      <Columns>
        <div className="admin-box-row-h2">{props.info.category}</div>
        <div className="admin-box-row-h2">{props.info.title}</div>
        <div className="admin-box-row-h2">{props.info.author}</div>
        <div className="admin-box-row-h6">{dayjs(props.info.writtenAt).format('YYYY.MM.DD')}</div>
        <div className={props.info.response ? 'admin-box-row-h7' : 'admin-box-row-h8'}>{props.info.response ? '답변 완료' : '대기중'}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ width: '60px', height: '30px' }}>
            <button className={props.info.response ? 'admin-box-row-b1' : 'admin-box-row-b3'} onClick={props.onAnswer}>
              {props.info.response ? '답변 수정' : '답변하기'}
            </button>
          </div>
          <div style={{ width: '60px', height: '30px' }}>
            <button className="admin-box-row-b2" onClick={props.onRemove}>
              삭제
            </button>
          </div>
        </div>
      </Columns>
    </Row>
  );
};
