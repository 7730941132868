import React, { ReactElement, useContext, useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { signout } from '../api';

import Logo from 'img/logo.png';
import LanguageIcon from 'img/language_icon.png';
import KoreaIcon from 'img/korea.png';
import VietnamIcon from 'img/vietnam.png';
import USAIcon from 'img/usa.png';

import '../css/Header.css';
import styled from 'styled-components';

const HeaderMenu = styled.div`
  width: 122px;
  height: 72px;
  border-radius: 8px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  left: 0;
  padding: 8px 10px;
  justify-content: space-around;
  align-items: stretch;
  .menu {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
`;

const languageLabel = new Map([
  ['ko-KR', 'KR'],
  ['en-US', 'EN'],
  ['vi', 'VI'],
]);

export default function Header(): ReactElement {
  const { t } = useTranslation();

  const [selectUserMenuOn, setSelectUserMenuOn] = useState(false);
  const [selectPointsMenuOn, setSelectPointsMenuOn] = useState(false);
  const [selectWithdrawMenuOn, setSelectWithdrawMenuOn] = useState(false);

  const [langOn, setLangOn] = useState<boolean>(false);
  return (
    <header style={{ height: '76px', boxShadow: 'inset 0 -1px 0 0 #a89670', backgroundColor: '#1c1b1a' }}>
      <div className="App-header">
        <Link to="/" className="Header-logo">
          <img src={Logo}></img>
        </Link>
        <div className="Header-link">
          <button className="Header-link-button" onClick={() => setSelectUserMenuOn(true)}>
            회원 관리
          </button>
          {selectUserMenuOn && (
            <HeaderMenu>
              <Link className="menu" to="/manage-business-users">
                비즈니스 회원
              </Link>
              <Link className="menu" to="/manage-users">
                일반 회원
              </Link>
            </HeaderMenu>
          )}
        </div>
        <div className="Header-link">
          <button className="Header-link-button" onClick={() => setSelectPointsMenuOn(true)}>
            포인트 관리
          </button>
          {selectPointsMenuOn && (
            <HeaderMenu>
              <Link className="menu" to="/manage-business-points">
                비즈니스 회원
              </Link>
              <Link className="menu" to="/manage-points">
                일반 회원
              </Link>
            </HeaderMenu>
          )}
        </div>
        <div className="Header-link">
          <button className="Header-link-button" onClick={() => setSelectWithdrawMenuOn(true)}>
            입출금 관리
          </button>
          {selectWithdrawMenuOn && (
            <HeaderMenu>
              <Link className="menu" to="/manage-business-withdraws">
                비즈니스 회원
              </Link>
              <Link className="menu" to="/manage-withdraws">
                일반 회원
              </Link>
            </HeaderMenu>
          )}
        </div>
        <div className="Header-link">
          <Link to="/manage-notices" className="Header-link-button">
            고객센터 관리
          </Link>
        </div>
        <div className="Header-link">
          <Link to="/manage-incentives" className="Header-link-button">
            인센티브 관리
          </Link>
        </div>

        <span style={{ margin: 'auto' }} />
        <Route
          render={(props) => (
            <button
              className="Header-link"
              onClick={async () => {
                await signout();
                props.history.push('/');
              }}
            >
              {t('main-menu-signout')}
            </button>
          )}
        />
        <div style={{ width: '120px', height: '76px', position: 'relative' }}>
          <button
            type="button"
            className={langOn ? 'Header-language-selected' : 'Header-language'}
            onClick={() => {
              setLangOn((o) => !o);
            }}
          >
            <img src={LanguageIcon} className="Header-language-logo" />
            <div className="Header-language-current">{languageLabel.has(i18n.language) ? languageLabel.get(i18n.language) : ''}</div>
            <div className="Header-language-arrow" />
          </button>
          {langOn && (
            <div className="Header-language-dropdown">
              <div className="Header-language-dropdown-title">LANGUAGE</div>
              <button
                className={i18n.language == 'ko-KR' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('ko-KR');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'ko-KR'}
              >
                <img src={KoreaIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={
                    i18n.language == 'ko-KR' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'
                  }
                >
                  한국어
                </div>
              </button>
              <button
                className={i18n.language == 'en-US' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('en-US');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'en-US'}
              >
                <img src={USAIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={
                    i18n.language == 'en-US' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'
                  }
                >
                  English
                </div>
              </button>
              <button
                className={i18n.language == 'vi' ? 'Header-language-dropdown-menu-selected' : 'Header-language-dropdown-menu'}
                onClick={() => {
                  i18n.changeLanguage('vi');
                  setLangOn(false);
                }}
                disabled={i18n.language == 'vi'}
              >
                <img src={VietnamIcon} style={{ width: '28px', height: '20px', margin: '0 8px' }} />
                <div
                  className={i18n.language == 'vi' ? 'Header-language-dropdown-menu-label-selected' : 'Header-language-dropdown-menu-label'}
                >
                  tiếng Việt
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
