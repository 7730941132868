import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import 'dayjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import Header from './Header';
import Footer from './Footer';

import '../css/AdminManageUsers.css';
import dayjs from 'dayjs';
import { getFAQ, newFAQ, updateFAQ } from '../api';

const EditorBlock = styled.div`
  .wrapper-class {
    width: 100%;
    margin: 0 auto;
  }
  .editor {
    height: 256px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 0 5px !important;
    border-radius: 2px !important;
    background-color: #ffffff;
  }
`;

const UploadBtn = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-color: transparent;
  background-color: #e0be7a;
  padding: 12px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
`;

interface FAQInfo {
  id: string;
  author: string;
  category: string;
  title: string;
  content: string;
  writtenAt: Date;
}

export default withRouter(function AdminWriteFAQ(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [FAQ, setFAQ] = useState<FAQInfo | null>(null);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      if (id) {
        setId(id);
        const result = await getFAQ(id);
        if (result) {
          setFAQ(result);
          setTitle(result.title);
          const blocksFromHtml = htmlToDraft(result.content);
          if (blocksFromHtml) {
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        }
      }
    })();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="admin-box-title">자주하는 질문 작성</div>
            <span style={{ margin: 'auto' }} />
            <div style={{ width: '54px' }}>
              <button className="admin-box-b2" onClick={() => props.history.push('manage-faqs')}>
                취소
              </button>
            </div>
          </div>
          <div style={{ height: '20px' }} />
          <div className="admin-box-h1">제목</div>
          <div style={{ height: '10px' }} />
          <div style={{ height: '32px' }}>
            <input
              className="admin-box-input"
              placeholder="제목을 입력해주세요."
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div style={{ height: '13px' }} />
          <div className="admin-box-h1">내용</div>
          <div style={{ height: '10px' }} />
          <EditorBlock>
            <Editor // 에디터와 툴바 모두에 적용되는 클래스
              wrapperClassName="wrapper-class"
              // 에디터 주변에 적용된 클래스
              editorClassName="editor"
              // 툴바 주위에 적용된 클래스
              toolbarClassName="toolbar-class"
              // 툴바 설정
              toolbar={{
                // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: false },
              }}
              placeholder="내용을 작성해주세요."
              // 한국어 설정
              localization={{
                locale: 'ko',
              }}
              // 초기값 설정
              editorState={editorState}
              // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
              onEditorStateChange={onEditorStateChange}
            />
          </EditorBlock>
          <div style={{ height: '20px' }} />
          <div style={{ display: 'flex', height: '44px' }}>
            <span style={{ margin: 'auto' }} />
            <div style={{ width: '120px' }}>
              <UploadBtn
                onClick={() => {
                  (async () => {
                    if (id == '') {
                      // 새 공지
                      const result = await newFAQ('공지', title, draftToHtml(convertToRaw(editorState.getCurrentContent())));
                      if (result) {
                        props.history.push('/manage-faqs');
                        return;
                      }
                    } else {
                      // 공지 수정
                      const result = await updateFAQ(id, '공지', title, draftToHtml(convertToRaw(editorState.getCurrentContent())));
                      if (result) {
                        props.history.push('/manage-faqs');
                        return;
                      }
                    }
                  })();
                }}
              >
                업로드
              </UploadBtn>
            </div>
          </div>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
