import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { listBusinessTransfers, revokeBusinessTransfer, TransferInfo } from '../api';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 140px 180px 140px 160px 100px 60px;
  align-items: center;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

export default withRouter(function AdminManageBusinessPoints(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);
  const [allTransfers, setAllTransfers] = useState<TransferInfo[]>([]);
  const [transfers, setTransfers] = useState<TransferInfo[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listBusinessTransfers();
      if (result == null) {
        return;
      }
      console.log(result);
      setTransfers(result);
      setAllTransfers(result);
    })();
  }, []);

  useEffect(() => {
    setTransfers(
      allTransfers.filter(
        (info) => info.email.toLowerCase().includes(search.toLowerCase()) || info.username.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search]);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="admin-box-title">비즈니스 회원 포인트 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <ColumnTitle>유저 아이디</ColumnTitle>
            <ColumnTitle>이메일</ColumnTitle>
            <ColumnTitle>지급 포인트</ColumnTitle>
            <ColumnTitle>입금 이더리움</ColumnTitle>
            <ColumnTitle>잔여 포인트</ColumnTitle>
            <ColumnTitle></ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {transfers.slice(page * 10, Math.min((page + 1) * 10, transfers.length)).map((user) => (
            <RowData info={user} />
          ))}
          {transfers.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(transfers.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(transfers.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(transfers.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(transfers.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(transfers.length / maxUserCount) - 1}
                    >
                      {Math.ceil(transfers.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(transfers.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(transfers.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: TransferInfo }) => {
  return (
    <Row>
      <Columns>
        <div className="admin-box-row-h1">{props.info.username}</div>
        <div className="admin-box-row-h2">{props.info.email}</div>
        <div className="admin-box-row-h3">{props.info.pointB}</div>
        <div className="admin-box-row-h3">
          {props.info.wei.length > 18
            ? props.info.wei.substring(0, props.info.wei.length - 18) + '.' + props.info.wei.substr(props.info.wei.length - 18, 3)
            : `0.${props.info.wei.slice(0, 3)}`}{' '}
          ETH
        </div>
        <div className="admin-box-row-h3">{props.info.currentPointB}</div>
        <button
          className="admin-box-row-b1"
          disabled={props.info.status == 'revoked'}
          onClick={() => {
            if (props.info.status == 'revoked') return;
            (async () => {
              const result = await revokeBusinessTransfer(props.info.transaction);
              if (result && result) {
                window.location.reload();
              }
            })();
          }}
        >
          {props.info.status == 'revoked' ? '취소 완료' : '지급 취소'}
        </button>
      </Columns>
    </Row>
  );
};
