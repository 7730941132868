import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Modal from 'react-modal';
import { getBusinessTree, StructedUserTree } from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/BusinessTree.css';

import Header from '../components/Header';
import Footer from '../components/Footer';

import dayjs from 'dayjs';
import styled from 'styled-components';

export default withRouter(function BusinessTree(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const [treeMembers, setTreeMembers] = useState<StructedUserTree[]>([]);
  const [username, setUserName] = useState('');
  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const username = urlParams.get('username');
      if (username) {
        setUserName(username);
        const members = await getBusinessTree(username);
        if (!members) {
          console.log('tree 가 없음');
        } else {
          console.log(members);
          console.log(members.length);
          setTreeMembers(members);
        }
      }
    })();
  }, []);

  function copyText(text: string) {
    if (!document.queryCommandSupported('copy')) {
      return alert(t('cannot-copy'));
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.dark(t('copy-success'), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    });
  }

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', margin: '80px auto 180px' }}>
        <div style={{ display: 'flex' }}>
          <Link className="BusinessTree-btn" to="/manage-business-users">
            이전 화면으로 돌아가기
          </Link>
        </div>
        <div style={{ height: '20px' }} />
        <div className="BusinessTree-box">
          <h1>{username}의 그룹</h1>
          <h2>*하부 7대 까지만 보이게 됩니다.</h2>
          <div style={{ height: '12px' }} />
          <Columns>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '20px' }} />
              <h1>유저 아이디</h1>
            </div>
            <h1>회원권</h1>
            <h1>회원 상태</h1>
            <h1>직접추천수당 L.P</h1>
            <h1>직접추천수당 E.P</h1>
            <h1>팀장인센티브 L.P</h1>
            <h1>팀장인센티브 E.P</h1>
            <h1>그룹 총 매출액</h1>
            <h1>그룹장조건 달성여부</h1>
            <h1>그룹장 인센티브 정산 예정</h1>
            <h1>보유 D.P</h1>
            <h1>보유 T.P</h1>
            <h1>보유 L.P</h1>
            <h1>보유 E.P</h1>
            <h1>Lv 1</h1>
            <h1>Lv 2</h1>
            <h1>Lv 3</h1>
            <h1>Lv 4</h1>
            <h1>Lv 5</h1>
            <h1>Lv 6</h1>
            <h1>Lv 7</h1>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {treeMembers.length > 0 && <Tree data={treeMembers} depth={0} />}
        </div>
      </div>

      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const Columns = styled.div`
  display: grid;
  grid-template-columns: 212px 48px 80px 94px 94px 94px 94px 80px 100px 129px 58px 57px 57px 57px 51px 51px 51px 51px 51px 51px 31px;
  align-items: center;
  h1 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  h2 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e0be7a;
    text-align: left;
  }
  h3 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  button {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 12px;
      height: 12px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

import FoldIcon from 'img/tree_fold.png';
import UnfoldIcon from 'img/tree_unfold.png';

const Tree = (props: { data: StructedUserTree[]; depth: number }) => {
  return (
    <>
      {props.data.map((e, i) => {
        const [fold, setFold] = useState(props.depth != 0);

        return (
          <>
            <Row>
              <Columns>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: `${(props.depth + 1) * 20}px` }}>
                    <h2>{props.depth}</h2>
                  </div>
                  <button onClick={() => setFold(!fold)}>
                    {fold && <img src={UnfoldIcon} />}
                    {!fold && <img src={FoldIcon} />}
                  </button>
                  <div style={{ width: '8px' }} />
                  <h2>{e.username}</h2>
                </div>
                <h3>{e.membership}</h3>
                <h3>{e.wasMembershipApproved ? '정회원' : '준회원'}</h3>
                <h2>{e.level1PointC}p</h2>
                <h2>{e.level1PointD}p</h2>
                <h2>{e.level2PointC}p</h2>
                <h2>{e.level2PointD}p</h2>
                <h2>{e.totalLevel7Sales}$</h2>
                <h2>{e.canReceiveLevel7Incentives ? '완료' : '미완료'}</h2>
                <h2>{/*e.level7PointC*/}0p</h2>
                <h2>{e.pointA}p</h2>
                <h2>{e.pointB}p</h2>
                <h2>{e.pointC}p</h2>
                <h2>{e.pointD}p</h2>
                {e.sales?.map((e, i) => {
                  if (i < 7)
                    return (
                      <h2>
                        {e.count}명/{e.sales}$
                      </h2>
                    );
                  else return null;
                })}
              </Columns>
            </Row>
            {!fold && <Tree data={e.children} depth={props.depth + 1} />}
          </>
        );
      })}
    </>
  );
};
