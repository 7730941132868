/* eslint-disable @typescript-eslint/no-non-null-assertion */
// const HOST = 'https://dev-api.max4.io';
const HOST = 'https://api.max4.io';
// const HOST = 'http://localhost:8000';

export function generateApiUrl(path: string): string {
  return `${HOST}/admin${path}`;
}

export async function signin(username: string, pw: string): Promise<true | null> {
  try {
    const response = await fetch(generateApiUrl('-sessions'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        pw,
      }),
    });

    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    const body = await response.json();
    localStorage.setItem('session', body.token);

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function signout(): Promise<void> {
  const session = localStorage.getItem('session');

  if (!session) return;

  localStorage.removeItem('session');

  try {
    await fetch(generateApiUrl(`-sessions/${session}`), {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }
}

export async function listUsers(): Promise<
  | {
      username: string;
      email: string;
      phone: string;
      pointA: number;
      pointB: number;
      pointC: number;
      pointD: number;
      wallet?: string;
      joinedAt: Date;
      recommender?: string;
    }[]
  | null
> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/users'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const user of result) {
      user.joinedAt = new Date(user.joinedAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface BusinessUserInfo {
  username: string;
  email: string;
  phone: string;
  wallet: string;
  membership?: string;
  wasMembershipApproved: boolean;
  wasMembershipApprovedAt?: Date;
  totalLevel7Sales: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  pendingPointA: number;
  pendingPointB: number;
  pendingPointC: number;
  pendingPointD: number;
  recommender: string;
  joinedAt: Date;
}

export async function listBusinessUsers(): Promise<BusinessUserInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-users'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();
    console.log(result);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeUser(username: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/users/${username}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeBusinessUser(username: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-users/${username}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function updateUser(username: string, fields: { password?: string; phone: string; wallet?: string }): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/users/${username}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function updateBusinessUser(
  username: string,
  fields: { password?: string; phone?: string; wallet?: string; membership?: string },
): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-users/${username}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface TransferInfo {
  username: string;
  email: string;
  currentPointB: number;
  status: string;
  pointB: number;
  wei: string;
  priceETH: number;
  pointBCoefficient: number;
  block: string;
  transaction: string;
  transferredAt: Date;
}

export async function listTransfers(): Promise<TransferInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/transfers'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const transfer of result) {
      transfer.transferredAt = new Date(transfer.transferredAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listBusinessTransfers(): Promise<TransferInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-transfers'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const transfer of result) {
      transfer.transferredAt = new Date(transfer.transferredAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function revokeTransfer(transaction: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/transfers/${transaction}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function revokeBusinessTransfer(transaction: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-transfers/${transaction}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface ExchangeInfo {
  username: string;
  email: string;
  id: string;
  status: string;
  wallet: string;
  point: number;
  price: number;
  fee: number;
  requestedAt: Date;
}

export async function listExchanges(): Promise<ExchangeInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/exchanges'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const exchange of result) {
      exchange.requestedAt = new Date(exchange.requestedAt);
    }
    console.log(result);
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listBusinessExchanges(): Promise<ExchangeInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-exchanges'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const exchange of result) {
      exchange.requestedAt = new Date(exchange.requestedAt);
    }
    console.log(result);
    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function markAsExchanged(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/exchanges/${id}`), {
      method: 'PATCH',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function markAsBusinessExchanged(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-exchanges/${id}`), {
      method: 'PATCH',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listNotices(): Promise<
  | {
      id: string;
      author: string;
      category: string;
      title: string;
      content: string;
      writtenAt: Date;
    }[]
  | null
> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/notices'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const article of result) {
      article.writtenAt = new Date(article.writtenAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getNotice(id: number | string): Promise<{
  id: string;
  author: string;
  category: string;
  title: string;
  content: string;
  writtenAt: Date;
} | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/notices/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    const article = await response.json();
    article.writtenAt = new Date(article.writtenAt);
    return article;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function newNotice(category: string, title: string, content: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/notices'), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        title,
        content,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function updateNotice(id: string, category: string, title: string, content: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/notices/${id}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        title,
        content,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeNotice(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/notices/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface InquiryInfo {
  id: string;
  private: boolean;
  author: string;
  category: string;
  title: string;
  content: string;
  writtenAt: Date;
  response?: {
    private: boolean;
    author: string;
    title: string;
    content: string;
    writtenAt: Date;
  };
}

export async function listInquiries(): Promise<InquiryInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/inquiries'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const article of result) {
      article.writtenAt = new Date(article.writtenAt);

      if (article.response) {
        article.response.writtenAt = new Date(article.response.writtenAt);
      }
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function listBusinessInquiries(): Promise<InquiryInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/business-inquiries'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const article of result) {
      article.writtenAt = new Date(article.writtenAt);

      if (article.response) {
        article.response.writtenAt = new Date(article.response.writtenAt);
      }
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getInquiry(id: number | string): Promise<InquiryInfo | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/inquiries/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    const article = await response.json();
    article.writtenAt = new Date(article.writtenAt);

    if (article.response) article.response.writtenAt = new Date(article.response.writtenAt);

    return article;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getBusinessInquiry(id: number | string): Promise<InquiryInfo | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/business-inquiries/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    const article = await response.json();
    article.writtenAt = new Date(article.writtenAt);

    if (article.response) article.response.writtenAt = new Date(article.response.writtenAt);

    return article;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function setInquiryResponse(id: string, title: string, content: string, isPrivate: boolean): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/inquiries/${id}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        content,
        private: isPrivate,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function setInquiryBusinessResponse(id: string, title: string, content: string, isPrivate: boolean): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/business-inquiries/${id}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title,
        content,
        private: isPrivate,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeInquiry(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/inquiries/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeBusinessInquiry(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/business-inquiries/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface FAQInfo {
  id: string;
  author: string;
  category: string;
  title: string;
  content: string;
  writtenAt: Date;
}

export async function listFAQs(): Promise<FAQInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/faqs'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();

    for (const article of result) {
      article.writtenAt = new Date(article.writtenAt);
    }

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function getFAQ(id: number | string): Promise<FAQInfo | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/faqs/${id}`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    const article = await response.json();
    article.writtenAt = new Date(article.writtenAt);
    return article;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function newFAQ(category: string, title: string, content: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/articles/faqs'), {
      method: 'POST',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        title,
        content,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status !== 201) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function updateFAQ(id: string, category: string, title: string, content: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/faqs/${id}`), {
      method: 'PUT',
      headers: {
        'Api-Token': session,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category,
        title,
        content,
      }),
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function removeFAQ(id: string): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/articles/faqs/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

import { toast } from 'react-toastify';

export function copyText(text: string, successMessage?: string, failMessage?: string): boolean {
  if (!failMessage) failMessage = '복사를 지원하지 않는 브라우저입니다.';
  if (!successMessage) successMessage = '복사 성공!';

  if (!document.queryCommandSupported('copy')) {
    alert(failMessage);
    return false;
  }
  const textarea = document.createElement('textarea');
  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
  toast.dark(successMessage, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    closeButton: false,
  });
  return true;
}

export interface IncentiveInfo {
  id: number;
  point: string;
  amount: number;
  createdAt: Date;
  reason: string;
  state: string;
  user: {
    pointA: number;
    pointB: number;
    pointC: number;
    pointD: number;
    pendingPointA: number;
    pendingPointB: number;
    pendingPointC: number;
    pendingPointD: number;
    email: string;
    pendingLevel7Rewards: string;
    totalLevel1RewardReceivedExceededAt?: Date;
    totalLevel1Sales: string;
    totalLevel1SalesReceived: string;
    totalLevel2Sales: string;
    totalLevel2SalesReceived: string;
    username: string;
    wasMembershipApproved: true;
    membership: {
      level1Percentage: number;
      level2Percentage: number;
      name: string;
      price: number;
    };
  };
}

export async function listIncentives(): Promise<IncentiveInfo[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl('/business-users/rewards'), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 401) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();
    console.log(result);

    return result;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export async function revokeIncentive(id: number): Promise<true | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-users/rewards/${id}`), {
      method: 'DELETE',
      headers: {
        'Api-Token': session,
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 404) return null;
    if (response.status !== 200) throw response;

    return true;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

export interface StructedUserTree {
  canReceiveLevel7Incentives: boolean;
  level1PointC?: string;
  level1PointD?: string;
  level2PointC?: string;
  level2PointD?: string;
  level7PointC?: string;
  membership?: string;
  pointA: number;
  pointB: number;
  pointC: number;
  pointD: number;
  totalLevel7Sales: string;
  username: string;
  wasMembershipApproved: boolean;
  wasMembershipApprovedAt?: Date;
  children: StructedUserTree[];
  sales?: {
    level: number;
    sales: string;
    count: number;
  }[];
}

export async function getBusinessTree(username: string): Promise<StructedUserTree[] | null> {
  const session = localStorage.getItem('session');

  if (!session) return null;

  try {
    const response = await fetch(generateApiUrl(`/business-users/${username}/tree`), {
      method: 'GET',
      headers: {
        'Api-Token': session,
        Accept: 'application/json',
      },
    });

    if (response.status === 400) return null;
    if (response.status === 401) return null;
    if (response.status === 412) return null;
    if (response.status !== 200) throw response;

    const result = await response.json();
    const result2 = [];
    result2.push(result);
    return result2;
  } catch (err) {
    console.error(err);
    alert('Error occurred.');
  }

  return null;
}

// export async function approveMembership(username: string): Promise<true | null> {
//   const session = localStorage.getItem('session');

//   if (!session) return null;

//   try {
//     const response = await fetch(generateApiUrl(`/business-users/${username}/approvals`), {
//       method: 'POST',
//       headers: {
//         'Api-Token': session,
//       },
//     });

//     if (response.status === 400) return null;
//     if (response.status === 401) return null;
//     if (response.status === 404) return null;
//     if (response.status !== 200) throw response;

//     return true;
//   } catch (err) {
//     console.error(err);
//     alert('Error occurred.');
//   }

//   return null;
// }
