import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TokenContext from '../token-context';

import Logo from 'img/signup_logo.png';
import '../css/Main.css';

export default withRouter(function Main(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (session) {
      props.history.push('/manage-users');
    } else {
      props.history.push('/signin');
    }
  }, []);

  return (
    <div className="App">
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
        <img src={Logo} />
        <div style={{ height: '30px' }} />
        <div className="main-h1">로그인 확인중</div>
      </div>
    </div>
  );
});
