import React, { ReactElement, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import 'dayjs';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';
import dayjs from 'dayjs';
import { copyText, ExchangeInfo, listBusinessExchanges, markAsBusinessExchanged } from '../api';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 110px 110px 180px 110px 110px 100px 60px;
  align-items: center;
`;

const ColumnTitle = styled.div`
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

export default withRouter(function AdminManageBusinessWithdraws(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);
  const [allExchanges, setAllExchanges] = useState<ExchangeInfo[]>([]);
  const [exchanges, setExchanges] = useState<ExchangeInfo[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listBusinessExchanges();
      if (result == null) {
        return;
      }
      console.log(result);
      setAllExchanges(result);
      setExchanges(result);
    })();
  }, []);

  useEffect(() => {
    setExchanges(
      allExchanges.filter(
        (info) => info.email.toLowerCase().includes(search.toLowerCase()) || info.username.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search]);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="admin-box-title">비즈니스 회원 입출금 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <ColumnTitle>신청 날짜</ColumnTitle>
            <ColumnTitle>유저 아이디</ColumnTitle>
            <ColumnTitle>이메일</ColumnTitle>
            <ColumnTitle>출금신청 계좌</ColumnTitle>
            <ColumnTitle>신청 포인트</ColumnTitle>
            <ColumnTitle>신청 금액</ColumnTitle>
            <ColumnTitle></ColumnTitle>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {exchanges.slice(page * 10, Math.min((page + 1) * 10, exchanges.length)).map((user) => (
            <RowData info={user} />
          ))}
          {exchanges.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(exchanges.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(exchanges.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(exchanges.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(exchanges.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(exchanges.length / maxUserCount) - 1}
                    >
                      {Math.ceil(exchanges.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(exchanges.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(exchanges.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: ExchangeInfo }) => {
  return (
    <>
      <ReactTooltip
        id={`wallet_${props.info.id}`}
        getContent={() => {
          return (
            <>
              {props.info.wallet}
              <br />
              클릭 시 복사됩니다.
            </>
          );
        }}
        place="bottom"
        offset={{ right: 95 }}
        clickable={false}
        type={'dark'}
        arrowColor={'transparent'}
        className="admin-tooltip-h3"
      />
      <Row>
        <Columns>
          <div className="admin-box-row-h6">{dayjs(props.info.requestedAt).format('YYYY.MM.DD')}</div>
          <div className="admin-box-row-h2">{props.info.username}</div>
          <div className="admin-box-row-h2">{props.info.email}</div>
          <button
            className="admin-box-row-h4"
            data-for={`wallet_${props.info.id}`}
            data-tip=""
            onClick={() => copyText(props.info.wallet, '지갑 주소 복사 성공!')}
          >
            계좌 보이기
          </button>
          <div className="admin-box-row-h3">{props.info.point.toFixed(2)}p</div>
          <div className="admin-box-row-h3">{(props.info.point * (1 - props.info.fee / 100)).toFixed(2)}$</div>
          <button
            className={props.info.status == 'pending' ? 'admin-box-row-b4' : 'admin-box-row-b3'}
            disabled={props.info.status != 'pending'}
            onClick={() => {
              (async () => {
                const result = await markAsBusinessExchanged(props.info.id);
                if (result && result) {
                  window.location.reload();
                }
              })();
            }}
          >
            {props.info.status == 'pending' ? '출금 완료' : '지급 완료'}
          </button>
        </Columns>
      </Row>
    </>
  );
};
