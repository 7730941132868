import React, { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { BusinessUserInfo, listBusinessUsers, removeBusinessUser, updateBusinessUser } from '../api';
import 'dayjs';

import Header from './Header';
import Footer from './Footer';

import PrevIcon from 'img/arrow_prev.png';
import NextIcon from 'img/arrow_next.png';

import '../css/AdminManageUsers.css';
import '../css/AdminManageBusinessUsers.css';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 100px 140px 100px 90px 80px 90px 80px 80px 140px;
  align-items: center;
  h1 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
  }
  h2 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e0be7a;
    text-align: left;
  }
  h3 {
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  .tooltipBtn {
    font-family: NotoSansKR;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e0be7a;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
  }
  h4 {
    opacity: 0.6;
    font-family: NotoSansKR;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
  }
  .approved {
    width: 60px;
    height: 30px;
    padding: 7px;
    border-radius: 4px;
    border: solid 1px #91bc6a;
    background: none;
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #91bc6a;
  }
  .notApproved {
    width: 60px;
    height: 30px;
    padding: 7px;
    border-radius: 4px;
    border: solid 1px #c96161;
    background: none;
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c96161;
  }
  .showGroup {
    width: 60px;
    height: 30px;
    padding: 7px;
    border-radius: 4px;
    border: solid 1px #e0be7a;
    background: none;
    font-family: NotoSansKR;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e0be7a;
  }
  .transffer {
    width: 60px;
    height: 30px;
    position: relative;
    .approveTransffer {
      width: 60px;
      height: 30px;
      padding: 7px;
      border-radius: 4px;
      border: solid 1px #e0be7a;
      background-color: transparent;
      font-family: NotoSansKR;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #e0be7a;
      :disabled {
        background-color: #e0be7a;
        color: #1c1b1a;
      }
    }
    .modifyTransffer {
      width: 60px;
      height: 30px;
      padding: 7px;
      border-radius: 4px;
      border: solid 1px #91bc6a;
      background: none;
      font-family: NotoSansKR;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #91bc6a;
    }
    .menu {
      width: 270px;
      height: 202px;
      padding: 10px;
      border: solid 2px #161514;
      background-color: #242322;
      position: absolute;
      left: 0;
      top: 30px;
      z-index: 1;
      h1 : {
        font-family: NotoSansKR;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #e0be7a;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
`;

import Icon_KR from 'img/korea.png';
import Icon_Vietnam from 'img/vietnam.png';
import dayjs from 'dayjs';

const CountryInfo = [
  { number: '+82', icon: Icon_KR },
  { number: '+84', icon: Icon_Vietnam },
];

export default withRouter(function AdminManageBusinessUsers(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();

  const maxUserCount = 10;
  const maxPageCount = 9;

  const [page, setPage] = useState<number>(0);

  const [dropdownOn, setDropdownOn] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<number>(0);

  const [editUser, setEditUser] = useState<BusinessUserInfo | null>(null);
  const [allUsers, setAllUsers] = useState<BusinessUserInfo[]>([]);
  const [users, setUsers] = useState<BusinessUserInfo[]>([]);
  const [search, setSearch] = useState('');

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [wallet, setWallet] = useState<string | undefined>('');
  const [recommender, setRecommender] = useState<string | undefined>('');
  const [membership, setMembership] = useState('');

  useEffect(() => {
    const session = localStorage.getItem('session');

    if (!session) {
      props.history.push('/signin');
      return;
    }

    (async () => {
      const result = await listBusinessUsers();
      if (result == null) {
        return;
      }
      setAllUsers(result);
      setUsers(result);
    })();
  }, []);

  useEffect(() => {
    setUsers(
      allUsers.filter(
        (info) =>
          info.email.toLowerCase().includes(search.toLowerCase()) ||
          info.username.toLowerCase().includes(search.toLowerCase()) ||
          info.phone.replace('-', '').replace('+', '').includes(search.replace('-', '').replace('+', '')),
      ),
    );
  }, [search]);

  // account.recommendees = Array.from({ length: 312 }, (v, i) => `id${i}`);

  return (
    <div className="App">
      <Modal
        ariaHideApp={false}
        isOpen={editUser != null}
        onAfterOpen={() => {
          if (editUser == null) return;
          setId(editUser.username);
          setPw('');
          for (let i = 0; i < CountryInfo.length; i++) {
            const country = CountryInfo[i];
            if (editUser.phone.startsWith(country.number)) {
              setCurrentCountry(i);
              setPhone(editUser.phone.substring(country.number.length));
            }
          }
          setEmail(editUser.email);
          setWallet(editUser.wallet);
          if (editUser.membership) setMembership(editUser.membership);
          else setMembership('');
          setRecommender(editUser.recommender);
        }}
        className="admin-edit-user-modal"
        style={{ overlay: { background: 'rgba(0, 0, 0, 0.7)' } }}
      >
        <div style={{ height: '35px', display: 'flex' }}>
          <div className="admin-edit-user-modal-h1">회원 정보 수정</div>
          <span style={{ margin: 'auto' }} />
          <button
            className="cancel"
            onClick={() => {
              setEditUser(null);
            }}
          >
            닫기
          </button>
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">아이디</div>
          <div className="admin-edit-user-modal-h2">비밀번호</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <input className="admin-edit-user-modal-input " type="text" value={id} disabled={true}></input>
          <input
            className="admin-edit-user-modal-input "
            type="password"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            placeholder="비밀번호 변경을 원하는 경우 입력하세요."
          ></input>
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">전화번호</div>
          <div className="admin-edit-user-modal-h2">이메일</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '80px', height: '32px', position: 'relative' }}>
              <button className="admin-dropdown" onClick={() => setDropdownOn((val) => !val)}>
                <img className="admin-dropdown-img" src={CountryInfo[currentCountry].icon} />
                <div className="admin-dropdown-label">{CountryInfo[currentCountry].number}</div>
                <div className="admin-dropdown-arrow" />
              </button>
              {dropdownOn && (
                <div className="admin-dropdown-control">
                  {CountryInfo.map((e, i) => {
                    return (
                      <button
                        type="button"
                        className="admin-dropdown-menu"
                        onClick={() => {
                          setDropdownOn(false);
                          setCurrentCountry(i);
                        }}
                      >
                        <img className="admin-dropdown-img" src={e.icon} />
                        <div style={{ width: '4px' }} />
                        <div className="admin-dropdown-label">{e.number}</div>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
            <span style={{ margin: 'auto' }} />
            <div style={{ width: '294px' }}>
              <input className="admin-edit-user-modal-input " type="text" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
            </div>
          </div>
          <input className="admin-edit-user-modal-input " type="text" value={email} disabled={true}></input>
        </div>
        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">회원 전용 이더리움 지갑 주소</div>
          <div className="admin-edit-user-modal-h2">추천인</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <input
            className="admin-edit-user-modal-input "
            type="text"
            value={wallet ? wallet : ''}
            onChange={(e) => setWallet(e.target.value)}
          ></input>
          <input className="admin-edit-user-modal-input " type="text" value={recommender ? recommender : ''} disabled={true}></input>
        </div>

        <div style={{ height: '20px' }} />
        <div style={{ height: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="admin-edit-user-modal-h2">회원권 상태</div>
          <div className="admin-edit-user-modal-h2">회원 상태</div>
        </div>
        <div style={{ height: '10px' }} />
        <div style={{ height: '32px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          <div className="dropdown">
            <button className="dropdown-button">{membership}</button>
            <div className="dropdown-content">
              <button onClick={() => setMembership('MAX1')}>MAX1</button>
              <button onClick={() => setMembership('MAX2')}>MAX2</button>
              <button onClick={() => setMembership('MAX3')}>MAX3</button>
              <button onClick={() => setMembership('MAX4')}>MAX4</button>
              <button onClick={() => setMembership('MAX5')}>MAX5</button>
            </div>
          </div>
          <input
            className="admin-edit-user-modal-input "
            type="text"
            value={editUser?.wasMembershipApproved ? '정회원' : '준회원'}
            disabled={true}
          ></input>
        </div>

        <div style={{ height: '34px' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '96px', height: '44px' }}>
            <button
              className="admin-btn-cancel"
              onClick={() => {
                if (editUser == null) return;
                (async () => {
                  const result = await removeBusinessUser(editUser.username);
                  if (result && result) {
                    window.location.reload();
                  }
                })();
              }}
            >
              회원 삭제
            </button>
          </div>
          <div style={{ width: '120px', height: '44px' }}>
            <button
              className="admin-btn-confirm"
              onClick={() => {
                if (editUser == null) return;
                if (
                  pw == '' &&
                  editUser.phone == CountryInfo[currentCountry].number + phone &&
                  editUser.wallet == wallet &&
                  editUser.membership == membership
                ) {
                  setEditUser(null);
                  return;
                }

                (async () => {
                  if (pw != '') {
                    await updateBusinessUser(editUser.username, {
                      password: pw,
                    });
                  }
                  if (editUser.phone != CountryInfo[currentCountry].number + phone) {
                    await updateBusinessUser(editUser.username, {
                      phone: CountryInfo[currentCountry].number + phone,
                    });
                  }
                  if (editUser.wallet != wallet) {
                    await updateBusinessUser(editUser.username, {
                      wallet: wallet,
                    });
                  }
                  if (editUser.membership != membership) {
                    await updateBusinessUser(editUser.username, {
                      membership: membership,
                    });
                  }

                  // const result = await updateBusinessUser(editUser.username, {
                  //   password: pw,
                  //   phone: CountryInfo[currentCountry].number + phone,
                  //   wallet: wallet,
                  //   membership: membership,
                  // });
                  // if (result && result) {
                  //   window.location.reload();
                  // }

                  window.location.reload();
                })();
              }}
            >
              수정 완료
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '96px auto 180px' }}>
        <div className="admin-business-box">
          <div style={{ display: 'flex', height: '35px' }}>
            <div className="title">비즈니스 회원 관리</div>
            <span style={{ margin: 'auto' }} />
            <input className="admin-box-search" placeholder="검색" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div style={{ height: '20px' }} />
          <Columns>
            <h1>유저 아이디</h1>
            <h1>이메일</h1>
            <h1>회원권</h1>
            <h1>소유 포인트</h1>
            <h1>가입일</h1>
            <h1>입금 요청일</h1>
            <h1>회원 상태</h1>
            <h1>그룹</h1>
            <h1>입금 확인</h1>
          </Columns>
          <div style={{ height: '10.5px' }} />
          <div style={{ height: '1px', border: 'solid 1px #ffffff' }} />
          {users.slice(page * 10, Math.min((page + 1) * 10, users.length)).map((user) => (
            <RowData info={user} onModifyUser={() => setEditUser(user)} />
          ))}
          {users.length > maxUserCount && (
            <>
              <div style={{ height: '10px' }} />
              <div style={{ display: 'flex', margin: '0 auto' }}>
                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.max(0, val - 1));
                  }}
                >
                  <img src={PrevIcon} />
                </button>
                {Math.ceil(users.length / maxUserCount) > maxPageCount ? (
                  <>
                    <button className="admin-page" onClick={() => setPage(0)} disabled={page == 0}>
                      1
                    </button>
                    {page > maxPageCount / 2 && <div className="admin-page">…</div>}
                    {Array.from({ length: maxPageCount - 2 }, (v, i) => {
                      const pageNum =
                        Math.min(
                          Math.max(Math.floor(maxPageCount / 2), page),
                          Math.ceil(users.length / maxUserCount) - Math.ceil(maxPageCount / 2),
                        ) +
                        i -
                        Math.floor((maxPageCount - 2) / 2);
                      return (
                        <button className="admin-page" onClick={() => setPage(pageNum)} disabled={page == pageNum}>
                          {pageNum + 1}
                        </button>
                      );
                    })}
                    {page < Math.ceil(users.length / maxUserCount) - Math.ceil(maxPageCount / 2) && <div className="admin-page">…</div>}
                    <button
                      className="admin-page"
                      onClick={() => setPage(Math.ceil(users.length / maxUserCount) - 1)}
                      disabled={page == Math.ceil(users.length / maxUserCount) - 1}
                    >
                      {Math.ceil(users.length / maxUserCount)}
                    </button>
                  </>
                ) : (
                  <>
                    {Array.from({ length: Math.ceil(users.length / maxUserCount) }, (v, i) => {
                      return (
                        <button className="admin-page" onClick={() => setPage(i)} disabled={page == i}>
                          {i + 1}
                        </button>
                      );
                    })}
                  </>
                )}

                <button
                  className="admin-page"
                  onClick={() => {
                    setPage((val) => Math.min(val + 1, Math.ceil(users.length / maxUserCount) - 1));
                  }}
                >
                  <img src={NextIcon} />
                </button>
              </div>
            </>
          )}
          <div style={{ height: '20px' }} />
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});

const RowData = (props: { info: BusinessUserInfo; onModifyUser: () => void }) => {
  const [showTransfferMenu, setShowTransfferMenu] = useState(false);

  return (
    <div key={props.info.username}>
      <ReactTooltip
        id={`points_${props.info.username}`}
        getContent={() => {
          return (
            <div className="admin-business-tooltip">
              <div className="point">
                <h1>데모 포인트</h1>
                <h2>{props.info.pointA}p</h2>
              </div>
              <h1>데모 포인트(미승인): {props.info.pendingPointA}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>토너먼트 포인트</h1>
                <h2>{props.info.pointB}p</h2>
              </div>
              <h1>토너먼트 포인트(미승인): {props.info.pendingPointB}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>라이브 포인트</h1>
                <h2>{props.info.pointC}p</h2>
              </div>
              <h1>라이브 포인트(미승인): {props.info.pendingPointC}p</h1>
              <div style={{ height: '7px' }} />
              <div className="point">
                <h1>이더리움 포인트</h1>
                <h2>{props.info.pointD}p</h2>
              </div>
              <h1>이더리움 포인트(미승인): {props.info.pendingPointD}p</h1>
            </div>
          );
        }}
        place="bottom"
        offset={{ right: 95 }}
        clickable={false}
        type={'dark'}
        arrowColor={'transparent'}
        key={`${props.info.username}_tooltip`}
      />
      <Row>
        <Columns>
          <h2>{props.info.username}</h2>
          <h3>{props.info.email}</h3>
          <h3>{props.info.membership ? props.info.membership : '없음'}</h3>
          <button className="tooltipBtn" data-for={`points_${props.info.username}`} data-tip="" key={`${props.info.username}_showtooltip`}>
            포인트 보기
          </button>
          <h4>{dayjs(props.info.joinedAt).format('YYYY.MM.DD')}</h4>
          <h4>{props.info.wasMembershipApproved ? dayjs(props.info.wasMembershipApprovedAt).format('YYYY.MM.DD') : ''}</h4>
          <div className={props.info.wasMembershipApproved ? 'approved' : 'notApproved'}>
            {props.info.wasMembershipApproved ? '정회원' : '준회원'}
          </div>
          <Link to={`/business-group?username=${props.info.username}`} className="showGroup" key={`${props.info.username}_showgroup`}>
            그룹 확인
          </Link>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.info.membership ? (
              <div className="transffer">
                <div className="modifyTransffer">입금 완료</div>
              </div>
            ) : (
              <div className="transffer">
                <div className="approveTransffer">입금 확인</div>
              </div>
            )}
            <button className="showGroup" onClick={props.onModifyUser} key={`${props.info.username}_modify`}>
              수정
            </button>
          </div>
        </Columns>
      </Row>
    </div>
  );
};
