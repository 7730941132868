import React, { ReactElement, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { signin } from '../api';
import Logo from 'img/signup_logo.png';
import Footer from './Footer';

import '../css/Signin.css';

export default withRouter(function Signin(props: RouteComponentProps): ReactElement {
  const { t } = useTranslation();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  async function onSignin() {
    const idValue = (id ?? '').trim();
    const pwValue = pw ?? '';

    if (!idValue || !pwValue) {
      alert(t('signin-alert-email-pw-required'));
      return;
    }

    const response = await signin(idValue, pwValue);

    if (!response) return;

    if (!response) {
      alert(t('signin-alert-failed'));
      return;
    }

    props.history.push('/manage-users');
  }

  return (
    <div className="App">
      <ToastContainer />
      <div className="Signin-main">
        <div className="Signin-logoside">
          <img src={Logo} className="ignin-logoside-logo" />
          <div style={{ height: '38px' }} />
          <div className="Signin-logoside-h1">MAX4</div>
          <div style={{ height: '16px' }} />
          <div className="Signin-logoside-h2">Best Trading Education Program</div>
        </div>
        <div className="Signin-infoside">
          <Link className="Signin-infoside-backbtn" to="/">
            {t('signin-back')}
          </Link>
          <div style={{ height: '20px' }} />
          <div className="Signin-infoside-box">
            <div style={{ height: '63px' }}>
              <div className="Signin-box-title">{t('main-menu-signin')}</div>
            </div>
            <div style={{ height: '30px' }}>
              <div className="Signin-info-label">{t('mypage-info-username-label')}</div>
            </div>
            <div style={{ height: '42px' }}>
              <input
                className="Signin-input-full"
                placeholder={t('signin-input-id')}
                type="text"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div>
            <div style={{ height: '30px' }}>
              <div className="Signin-info-label">{t('signin-pw-placeholder')}</div>
            </div>
            <div style={{ height: '42px' }}>
              <input
                className="Signin-input-full"
                placeholder={t('mypage-input-password')}
                type="password"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
            </div>
            <div style={{ height: '40px' }} />
            <button className="Signin-button" type="button" onClick={onSignin}>
              {t('main-menu-signin')}
            </button>
          </div>
        </div>
      </div>
      <span style={{ margin: 'auto' }} />
      <Footer />
    </div>
  );
});
