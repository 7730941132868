import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TokenContext from '../token-context';
import '../css/Footer.css';

import Logo from '../img/logo.png';

export default function Footer(): ReactElement {
  const { t } = useTranslation();
  const tokenContext = useContext(TokenContext);

  return (
    <footer style={{ height: 'auto', boxShadow: 'inset 0 1px 0 0 #a89670', backgroundColor: '#1c1b1a' }}>
      <div className="App-footer">
        <div style={{ height: '20px' }} />
        <img src={Logo} className="Footer-logo" />
        <div style={{ height: '20px' }} />
        <div className="Footer-h1">ⓒ MAX SIGNAL 2021 All rights reserved.</div>
        <div style={{ height: '20px' }} />
        <div className="Footer-h2">{t('footer-support')}</div>
        <div style={{ height: '5px' }} />
        <div className="Footer-h1">maxsignalhongkong@gmail.com</div>
        <div style={{ height: '20px' }} />
      </div>
    </footer>
  );
}
